<template>

<app-content>

	<app-content-head title="Settings" subtitle="Manage your billing settings.">

	</app-content-head>

	<app-content-body>

		<app-content-section :manual="true" :danger="true" :loading="is.creating" icon="bank" v-on:click="onSetupClick" title="Connect Bank Account" description="To receive daily payouts of your registration revenue you must first verify your identity and connect your bank account. Click to begin." v-if="!$organisation.stripe.created" />

		<app-content-section :manual="true" :danger="true" :loading="is.verifying" icon="identity" v-on:click="onLinkClick" title="Continue Verifying Identity" :description="verifyContinue" v-if="$organisation.stripe.created && !$organisation.stripe.verified" />

		<app-content-section :manual="true" :disabled="true" :success="true" :tick="true" icon="identity" title="Identification Verified" description="You have successfully completed identification verification." v-if="$organisation.stripe.verified" />

		<app-content-box title="Confirm Bank Account" icon="bank" v-if="!$organisation.stripe.completed && $organisation.stripe.created">

			<p>You must connect your bank account to be able to receive payouts.</p>

			<com-bank-account v-on:completed="onCompleted" />

		</app-content-box>

		<app-content-box title="Change Bank Account" icon="bank" v-if="$organisation.stripe.completed && is.changing">

			<com-bank-account :can-cancel="true" v-on:cancelled="is.changing = false" v-on:completed="is.changing = false" />

		</app-content-box>

		<app-content-section :manual="true" :success="true" :tick="true" :loading="is.changing" icon="bank" v-on:click="onChangeClick" title="Bank Account Connected" description="You have successfully connected your bank account and are ready to receive payouts. Click to change your details." v-if="$organisation.stripe.completed && !is.changing" />

	</app-content-body>

</app-content>

</template>

<script>

import comBankAccount from './organisationbillingsettings/BankAccount'

export default {

	components: {
		comBankAccount
	},

	data: function() {

		return {
			is: {
				creating: false,
				verifying: false,
				changing: false
			},
			settings: true
		}

	},

	computed: {

		verifyContinue: function() {

			var text = ['You started but did not finish the identity verification process']

			if (this.$organisation.stripe.requirements) {

				if (this.$organisation.stripe.requirements.errors) {

					if (this.$organisation.stripe.requirements.errors.length) {

						this.$_.each(this.$organisation.stripe.requirements.errors, function(error) {

							text.push(error.reason)

						})

					}

				}

			}

			text.push('Click to continue')

			return text.join('. ')

		}

	},

	methods: {

		onCompleted: function() {

			window.location.reload()

		},

		onChangeClick: function() {

			this.is.changing = true

		},

		onSetupClick: function() {

			this.is.creating = true

			this.$api.post('organisation/billing/settings/setup').then(function() {

				this.onLinkClick()

			}.bind(this))

		},

		onLinkClick: function() {

			this.is.verifying = true

			this.$api.post('organisation/billing/settings/link').then(function(json) {

				window.location.href = json.url

			}.bind(this))

		}

	}

}

</script>

<style scoped>

</style>